import React from "react";
import { NavLink } from "react-router-dom"
import home from '../assets/home_page.svg'
import hoodie from '../assets/hoodie.svg'
import contact from '../assets/contact.svg'
import '../styles/style.css'

const Header = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg bg-light">
                <div className="container-fluid">
                    <NavLink to="/"> <h1 className="navbar-logo glitch my-3" >4Getter</h1> </NavLink>
                    <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 m-auto">
                            <li className="nav-item mx-5">
                                <NavLink activeclassname="active" className="nav-link nav-svg" to="/" > <img className="svg" src={home} alt="home"/> </NavLink>
                                <NavLink activeclassname="active" className="nav-link nav-text" to="/" > Home </NavLink>
                            </li>
                            <li className="nav-item mx-5">
                                <NavLink activeclassname="active" className="nav-link nav-svg" to="/products" ><img className="svg" src={hoodie} alt="hoodie"/></NavLink>
                                <NavLink activeclassname="active" className="nav-link nav-text" to="/products" > Products </NavLink>
                            </li>
                            <li className="nav-item mx-5">
                                <NavLink activeclassname="active" className="nav-link nav-svg" to="/contact" ><img className="svg"  src={contact} alt="contact"/></NavLink>
                                <NavLink activeclassname="active" className="nav-link nav-text" to="/contact" > Contact </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <hr className="navbar-end"/>
        </header>
    )
}

export default Header

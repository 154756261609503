import React from "react";
import '../styles/homepage.css'
import {Link} from "react-router-dom";
import ProductPage from "./ProductPage";

const HomePage = () => {
    return (
        <div className="home">
            <h1 className="logo">4GETTER</h1>
            <img className="homeBackground" src="https://images.pexels.com/photos/572463/pexels-photo-572463.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260" alt="homepage"/>
            <label className="description mx-5 text-white-50">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur debitis eaque enim et expedita fugit inventore molestias repellendus voluptates. Accusamus assumenda atque consectetur corporis eius exercitationem fugit in, ipsa itaque iure laudantium molestiae nemo, obcaecati quidem, repudiandae saepe sit temporibus tenetur voluptas voluptatum? Debitis dignissimos inventore pariatur reprehenderit rerum voluptatum.
            </label>
            <Link to="/products"
                  className="d-flex justify-content-center align-items-end my-5 text-decoration-none"
                  element={<ProductPage/>}>
                <button className="btn btn-outline-secondary text-light" > Let's Explore! </button>
            </Link>
        </div>
    )
}
export default HomePage
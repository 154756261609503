import { initializeApp } from 'firebase/app'
import { getDatabase} from 'firebase/database';
import {onValue, ref} from "firebase/database";
const firebaseConfig = {
    apiKey: "AIzaSyA7wVzCNCRgnhf3Un8XtZdTC6nSDlR0K2I",
    authDomain: "getter-f5148.firebaseapp.com",
    databaseURL: "https://getter-f5148-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "getter-f5148",
    storageBucket: "getter-f5148.appspot.com",
    messagingSenderId: "880331829660",
    appId: "1:880331829660:web:08efe6b8374e0d7a71d859"
};
const app = initializeApp(firebaseConfig)

const db =  getDatabase(app)

export {db as default, onValue, ref}
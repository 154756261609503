import React from "react";
import ProductList from "./ProductList";
import db, { onValue, ref } from '../firebase/firebaseConfig'
import '../styles/product.css'
let arr = []
let key = 0;
const products = ref(db, 'products/Hoodie');
onValue(products, (snapshot) => {
    const data = snapshot.val();
    for (let i in data) {
        arr.push(data[i])
    }
})

const ProductPage = () => {
    return (
        <div className="container">
            <div className="row my-5">
                {arr.map((product) => (
                    <ProductList key={key++} product={product} />
                ))}
            </div>
        </div>
    )
}
export default ProductPage
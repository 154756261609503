import React from "react";

const ContactPage = () => {
    return (
        <div className="contact-body container mt-3 text-light">
            <h1 className="text-center">Contact Address</h1>
            <hr className="navbar-end my-3"/>
            <div className="row">
                <div className="col-sm-8">
                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d41243675.22032553!2d-130.13083074373216!3d50.8808955614878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sKanada!5e0!3m2!1str!2str!4v1647507700676!5m2!1str!2str" width="100%" height="320"  style={{border:'0'}} allowFullScreen> </iframe>
                </div>
                <div className="col-sm-4" id="contact2">
                    <h3>Locations and Contacts</h3>
                    <hr align="left" width="50%"/>
                    <h4 className="pt-2 text">Address</h4>
                    <i id="logo" className="fas fa-globe p-2" ></i>
                    <span className="text">address</span> <br/>
                    <h4 className="pt-2">Contact</h4>
                    <i id="logo" className="fas fa-phone p-2" ></i>
                    <a className="text me-5" href="tel:+">1234556</a>
                    <i id="logo" className="fab fa-whatsapp p-2" ></i>
                    <a className="text" href="tel:+">123456</a>
                    <h4 className="pt-2">Email</h4>
                    <i id="logo" className="fa fa-envelope p-2" ></i>
                    <a className="text" href="mailto:">test@test.com</a>
                </div>
            </div>
        </div>
    )
}
export default ContactPage
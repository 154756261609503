import React from "react";
import Header from "../Components/Header";
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import HomePage from "../Components/HomePage";
import ProductPage from "../Components/ProductPage";
import ContactPage from "../Components/ContactPage";

const Routers = () => {
    return (
        <BrowserRouter>
            <div >
                <Header></Header>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/products" element={<ProductPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                </Routes>
            </div>
        </BrowserRouter>
    )
}
export default Routers
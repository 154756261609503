import React from "react";

const ProductList = (product) => {
    return (
        <div id="mquery" className="col-md-3">
            <a href={product.product.link} target="_blank" rel="noreferrer">
                <div className="card text-white card-has-bg click-col"
                    style={{ backgroundImage: `url(${product.product.img})` }}>
                    <div className="card-img-overlay d-flex flex-column ">
                        <div className="card-body"></div>
                        <div id="price" className="card-body price "><strong>{product.product.price}</strong></div>
                        <div className="media ">
                            <div className="card-footer ">
                                <div className="media-body">
                                    <span className="card-meta mb-2"><strong>{product.product.name}</strong></span>
                                </div>
                                <small style={{ color: '#c1821f' }}>{product.product.category}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}
export default ProductList